
.main {
  margin: 20px;
}

.changeTask {
  margin-top: 40px;
}

.name {
   margin-bottom: 40px;
   word-break: break-all;
}

.tooltip {
    position: relative;
    display: block;
  }

  /* Tooltip text */
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 3px;
    top: 24px;

    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
  }

  /* Show the tooltip text when you mouse over the tooltip container */
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
