/*============= id ============*/
/* #outter2 {
    text-align: center;
} */

#allpanel {
    display: grid;
    grid-template-rows: 0fr 0fr 10fr;
}


/*============= className ============*/

.status {
    position: relative;
    float: right;
    height: 26px;
}



.height-26px {
    height: 26px;
}



.border-none:hover {
    background-color: var(--blue-0bf);
    font-weight: bold;
    color: white
}

.border-style {
    border-top: solid 0.5px grey;
    display: grid;
    grid-template-columns: 5fr 2.5fr 2.5fr;
}


.width-387p {
    width: 387px;
}


.width-70 {
    width: 70%;
}

.width-30 {
    width: 30%;
}

/*============ html tag =============*/



ul {
    padding: 5px;
    margin: 1px 8px;
}

.complete-button {
    border: none;
}

.summary {
    margin: 20px;
}

.numContainer {
    display: flex;
}

.numCon {
  width: 75px;
  margin-right: 10px;
  background-color: white;
  border-radius: 5px;
  border: 1px solid black;
}

.num {
  font-size: 20px;
  font-weight: bold;
}

.taskName {
    word-break: break-all;
}

.createListButton:disabled {
    cursor: unset;
    background-color: lightgray;
}

.createListButton:disabled:hover {
    cursor: unset;
    background-color: lightgray;
}
