/* TODO Add site wide styles */
:root {
  --blue-0bf: #0060bf;
  --blue-7f5: #d7e7f5;
  --blue-7ef: #bfd7ef;
  --blue-fcc: #317fcc;
  --blue-dff: #009dff;
  --blue-ecc: #007ecc;
  --blue-0cc: #3380cc;
  --gray-9d9: #d9d9d9;
  --gray-ec1: #bdbec1;
  --gray-b94: #8b8b94;
  --white-ef2: #cadef2;

}
/*============ html tag =============*/
body {
  margin: 0px;
}

a:link { text-decoration: none; }

li {
  list-style-type: none;
}

#modal-content {
  border-radius: 5px;
}


/*============= className ============*/
.bg-blue-0bf {
  background-color: var(--blue-0bf);
}

.bg-blue-0cc {
  background-color: var(--blue-0cc);
}

.bg-blue-fcc {
  background-color: var(--blue-fcc);
}

.bg-blue-dff {
  background-color: var(--blue-dff);
}

.bg-blue-7f5 {
  background-color: var(--blue-7f5);
}

.bg-blue-dff:hover {
  background-color: var(--blue-ecc);
  color: white
}

.bg-blue-7ef-hover:hover {
  background-color: var(--blue-7ef);
}

.bg-blue-7f5-hover:hover {
  background-color: var(--blue-7f5);
}

.bg-transp {
  background-color: transparent;
}

.bg-white {
  background-color: white;
}

.border-blue {
  border: 2px blue solid;
}

.border-orange {
  border: 2px orange solid;
}

.border-left-black {
  border-left: 1px solid black;
}

.border-gray-9d9 {
  border: 2px solid var(--gray-9d9);
}

.border-white-ef2 {
  border: 2px solid var(--white-ef2);
}

.border-0 {
  border: 0px;
}

.borderR-5p {
  border-radius: 5px;
}

.borderR-10p {
  border-radius: 10px;
}

.boxS-0-0-3-black {
  box-shadow: 0px 0px 3px black
}

.color-white {
  color: white;
}

.color-white-ef2 {
  color: var(--white-ef2)
}

.color-gray-9d9 {
  color: var(--gray-9d9)
}

.color-gray-ec1 {
  color: var(--gray-ec1)
}

.color-gray-b94 {
  color: var(--gray-b94)
}

.color-black {
  color: black;
}

.color-black-hover:hover {
  color: black;
}

.color-red {
  color: red;
}

.color-blue-dff-hover:hover {
  color: var(--blue-dff);
}

.color-blue-0bf-hover:hover {
  color: var(--blue-0bf);
}

.cursor-p:hover {
  cursor: pointer;
}

.dis-block{
  display: block;
}

.flx {
  display: flex;
}

.flx-col {
  display: flex;
  flex-direction: column;
}

.flx-jc-center {
  display: flex;
  justify-content: center;
}

.flx-jc-sa {
  display: flex;
  justify-content: space-around;
}

.flx-jc-sb {
  display: flex;
  justify-content: space-between;
}

.flx-jc-se {
  display: flex;
  justify-content: space-evenly;
}

.flx-jc-fe {
  display: flex;
  justify-content: flex-end;
}

.flx-ac-center {
  align-content: center;
}

.flx-ai-center {
  align-items: center;
}

.flx-wrap {
  flex-wrap: wrap;
}

.fontF-hel{
  font-family: Arial, Helvetica, sans-serif;
}

.fontS-115rem {
  font-size: 1.15rem;
}

.fontS-125rem {
  font-size: 1.25rem;
}

.fontS-175rem {
  font-size: 1.75rem;
}

.fontS-200rem {
  font-size: 2rem;
}

.fontS-300rem {
  font-size: 3rem;
}


.fontW-600 {
  font-weight: 600;
}

.gap15p {
  gap:15px;
}

.grid-1-1 {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.grid-2-8 {
  display: grid;
  grid-template-columns: 2fr 8fr;
}

.height-88vh {
  height: 88vh;
}

.height-fit {
  height: fit-content;
}

.mrg0p {
  margin: 0px;
}

.mrg20p {
  margin-right: 20px;
}

.mrg-lr-auto {
  margin-left: auto;
  margin-right: auto;
}

.mrg-l-10p {
  margin-left: 10px;
}

.mrg-l-7rem {
  margin-left: 7rem;
}

.mrg-r-3rem {
  margin-right: 3rem;
}

.mrg-r-8p {
  margin-right: 8px;
}

.mrg-t-0p {
  margin-top: 0px;
}

.pad8p {
  padding: 8px;
}

.pad10p {
  padding: 10px;
}

.pad-lr-10p {
  padding-left: 10px;
  padding-right: 10px;
}

.pad-lr-150rem {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.pad-t-4rem {
  padding-top: 4rem;
}

.pad-tb-5p {
  padding-top: 5px;
  padding-bottom: 5px;
}

.pad-tb-10p {
  padding-top: 10px;
  padding-bottom: 10px;
}

.pad-tb-15p {
  padding-top: 15px;
  padding-bottom: 15px;
}

.pad-tb-25p {
  padding-top: 25px;
  padding-bottom: 25px;
}

.padding-left-13px {
  padding-left: 13px;
}

.padding-top-13px {
  padding-top: 13px
}

.pos-rel {
  position: relative;
}

.pos-abs {
  position: absolute;
}

.right-0p {
  right: 0px
}

.txt-ali-center {
  text-align: center;
}

.width-max-250p {
  max-width: 250px;
}

.width-max-450p {
  max-width: 450px;
}

.width-max-700p {
  max-width: 700px;
}

.width-max-750rem {
  max-width: 7.5rem;
}

.width-750rem {
  width: 7.5rem;
}

.width-fit {
  width: fit-content;
}

button:hover {
  cursor: pointer
}

select:hover {
  cursor: pointer
}

.width-100per {
  width: 100%;
}

.va-center {
  display: flex;
  align-items: center;
}

.border-bottom-black {
  border-bottom: 1px solid black;
}
